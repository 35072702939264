import React from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import Enterprise from '../../views/Enterprise';
import { Helmet } from 'react-helmet';
import { Section, SectionAlternate } from 'components/organisms';
import { GetStarted, Features, Reviews, QuickStart, Services, Hero } from './components';

const useStyles = makeStyles(() => ({
  sectionAlternateNoPaddingTop: {
    '& .section-alternate__content': {
      paddingBottom: 0,
    },
  },
  dividerSection: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const IndexView = ({ themeMode }) => {
  const classes = useStyles();

  return (
    <div>
      <Enterprise />
    </div>
  );
};

export default IndexView;
